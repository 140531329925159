.display-block:hover {
    background-color: #E8E8E8;
    border-radius: 2px;
    cursor: pointer;
}

.documentTitle:hover {
    background-color: #E8E8E8;
    border-radius: 2px;
    cursor: pointer;
}